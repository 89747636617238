<template>
	<div>
		<van-popup v-model="$store.state.showPopup" :class="`${$store.state.xiazApp?'popup-conter-app':'popup-conter'}`"
			@close="SuporteIs=false" :overlay-class="`${$store.state.xiazApp?'popup-box-app':'popup-box'}`"
			position="left">
			<div class="my-scrollbar my-scrollbar-none" style="width: 3rem;overflow-x: scroll">

				<div class="X_tOtMUxMTenSfTn7J28">
					
					<div @click="$router.push({name:'search'})" class="searchWrap-AApaE"><span>Pesquisar</span><span role="img" aria-label="search"
							class="anticon anticon-search">
							<svg-icon className="fs20" icon-class="comm_icon_ss--svgSprite:all" />
							</span></div>
				</div>
				<!--  -->
				<div class="X_tOtMUxMTenSfTn7J28 game-menu__items" style="padding-top: .1rem;">
					<div v-if="!is_vuex_token || (is_vuex_token && item.textName!='_Demo')" @click="tabFun(index)"
						:class="$store.state.active==index?'O8zRRZalAqyl6XRHwyev ia6woxHBwCJOiUSohzV6 game-menu__item':' O8zRRZalAqyl6XRHwyev game-menu__item'"
						v-for="(item,index) in iconList" :key="index">
						<svg-icon v-if="$store.state.active!=index" className="JGYMaLsdGfKSq3qags9Y"
							:icon-class="item.svg" />
						<img v-else :src="activeList[index]" alt="" class="JGYMaLsdGfKSq3qags9Y">
						<div class="IDcevVi1oQJGuthlvopy text"> {{$t('popup.nav')[index].title}}</div>
					</div>
				</div>
				<div class="vsmXMvkgXQ1ZupbTcU60">
					<Audio></Audio>
					<!-- {{_vuex_token()}} -->
					<!-- .is_vuex_token -->
					<!-- @click="" -->
					<div @click="is_vuex_token?$router.push({name:'report'}):$store.commit('isRecord'),$store.commit('publicFun',['isRecordType',1])"
						class="JzmqmQEmD_EojrBge1qC yhSUVDFwjkr5PRBtBCUM betting-record">
						<svg-icon className="JzmqmQEmD_EojrBge1qC_iam" icon-class="icon_sy_zc_tzjl--svgSprite:all" />
						{{$t('popup._Apostas')}}
						<!--  -->
					</div>
					<div @click="$router.push({name:'promote'})"
						class="JzmqmQEmD_EojrBge1qC yhSUVDFwjkr5PRBtBCUM betting-record">
						<svg-icon className="JzmqmQEmD_EojrBge1qC_iam" icon-class="icon_sy_zc_tgzq--svgSprite:all" />
						{{$t('popup._Agente')}}
					</div>

					<div class="yfF9WZOWUEDWBJAnHyPf items">
						<div class="a6z38LxCJRwWacRIx6d9 event-title">
							<span>{{$t('popup._Promoção')}}</span>
						</div>
						<div @click="$router.push({name:'event'})"
							class="_037LxwuCKN9WUGgvrvgg item Xx6KWL671rTVnrm7j3in">
							<img src="./../../assets/icons/images/btn_sy_zc_hd.webp" style="height: 0.8rem;">
							<span>{{$t('popup._Eventos')}}</span>
						</div>

						<div @click="$router.push({name:'canReceive'})"
							class="_037LxwuCKN9WUGgvrvgg item Xx6KWL671rTVnrm7j3in">
							<img src="./../../assets/icons/images/btn_sy_zc_jl.webp" style="height: 0.8rem;">
							<span>{{$t('popup._Pendente')}}</span>
						</div>

						<div @click="$router.push({name:'records'})"
							class="_037LxwuCKN9WUGgvrvgg item Xx6KWL671rTVnrm7j3in">
							<img src="./../../assets/icons/images/btn_sy_zc_lqjl.webp" style="height: 0.8rem;">
							<span>{{$t('popup._Histórico')}}</span>
						</div>
						<div @click="$router.push({name:'rechargeFund'})"
							class="_037LxwuCKN9WUGgvrvgg item Xx6KWL671rTVnrm7j3in">
							<img src="./../../assets/icons/images/btn_sy_zc_gjj.webp" style="height: 0.8rem;">
							<span>{{$t('popup._FundodePrevidência')}}</span>
						</div>

						<div @click="$router.push({name:'yuebao'})"
							class="_037LxwuCKN9WUGgvrvgg item Xx6KWL671rTVnrm7j3in">
							<img src="./../../assets/icons/images/btn_sy_zc_yeb.webp" style="height: 0.8rem;">
							<span>{{$t('popup._Juros')}}</span>
						</div>
						<div @click="$router.push({name:'vip'})"
							class="_037LxwuCKN9WUGgvrvgg item Xx6KWL671rTVnrm7j3in">
							<img src="./../../assets/icons/images/btn_sy_zc_vip.webp" style="height: 0.8rem;">
							<span>{{$t('popup._VIP')}}</span>
						</div>
					</div>

					<div class="yVxgEnjFwfNHfoePIA4w other-group">
						<div id="sss" @click="SuporteIs=!SuporteIs"
							class="yhSUVDFwjkr5PRBtBCUM KYJ6GhRbRQ3eAaeyFhEg other-item contact-us"
							style="justify-content: space-between;padding-right: .2rem;">
							<div style="display: flex;">
								<div class="dDFOAjUKC5bZ6pGH7_p0 icon-wrap" style="background: transparent;">
									<svg-icon className="bgcolor" :style="{
                                    color:SuporteIs?'var(--cms-global-spare7-color)':'var(--cms-global-spare7-color)'
                                }" icon-class="comm_icon_language--svgSprite:all" />
								</div>

								<div class="_llZzyHSRes4dPHH7fN2">
									{{$store.state._i18nLocale=='pt'?'Português':'English'}}</div>
							</div>
							<svg-icon className="bgcolor_a" :style="{
                                    color:SuporteIs?'var(--cms-global-spare7-color)':'var(--cms-global-spare7-color)',
                                    transform:SuporteIs?'rotate(-90deg)':'',
                                }" icon-class="comm_icon_sort--svgSprite:all" />
							<div v-if="SuporteIs" class="ant-popover-inner">
								<div class="ant-menu-item" @click="switchLang('pt')"
									:style="{color:`${$store.state._i18nLocale=='pt'?'var(--cms-primary-color)':''}`}">
									<span class="V4LeFhG0b9lZSmJT8Ynm switch-lang__text">Português</span></div>
								<div class="ant-menu-item" @click="switchLang('en')"
									:style="{color:`${$store.state._i18nLocale=='en'?'var(--cms-primary-color)':''}`}">
									<span class="V4LeFhG0b9lZSmJT8Ynm switch-lang__text">English</span></div>
							</div>

						</div>
						<div class="yhSUVDFwjkr5PRBtBCUM KYJ6GhRbRQ3eAaeyFhEg other-item contact-us"
							@click="$store.commit('publicFun',['AppDownload',true])">
							<div class="dDFOAjUKC5bZ6pGH7_p0 icon-wrap">
								<svg-icon className="bgcolor" icon-class="icon_sy_zc_xz--svgSprite:all" />
							</div>
							<div class="_llZzyHSRes4dPHH7fN2">{{$t('popup._BaixarApp')}}</div>
						</div>
						<div @click="$router.push({name:'notice'})"
							class="yhSUVDFwjkr5PRBtBCUM KYJ6GhRbRQ3eAaeyFhEg other-item contact-us">
							<div class="dDFOAjUKC5bZ6pGH7_p0 icon-wrap">
								<svg-icon className="bgcolor" icon-class="icon_sy_zc_kf--svgSprite:all" />
							</div>
							<div class="_llZzyHSRes4dPHH7fN2">{{$t('popup._Suporte')}}</div>
						</div>
						<div @click="$router.push({name:'notice'})"
							class="yhSUVDFwjkr5PRBtBCUM KYJ6GhRbRQ3eAaeyFhEg other-item contact-us">
							<div class="dDFOAjUKC5bZ6pGH7_p0 icon-wrap">
								<svg-icon className="bgcolor" icon-class="icon_sy_zc_cjwt--svgSprite:all" />
							</div>
							<div class="_llZzyHSRes4dPHH7fN2">{{$t('popup._FAQ')}}</div>
						</div>
						<!-- 'site_about' -->
						<div @click="$router.push({name:'details',query:{
							title:$t('popup._Sobre'),
							isConter:true,
							content:$store.state.config.site_about
						}})" class="yhSUVDFwjkr5PRBtBCUM KYJ6GhRbRQ3eAaeyFhEg other-item contact-us">
							<div class="dDFOAjUKC5bZ6pGH7_p0 icon-wrap">
								<svg-icon className="bgcolor" icon-class="icon_sy_zc_gywm--svgSprite:all" />
							</div>
							<div class="_llZzyHSRes4dPHH7fN2">{{$t('popup._Sobre')}}</div>
						</div>






					</div>
					<div class="NQ4WM0y42kgXYhY5nCtm offical-channel">
						<p class="DBxipifklFUXsk1HBEsp">Canal Oficial</p>
						<ul class="VmQmq_aGsrNiKVkUlbUu">
							<li class="A_AasZeOJGaSJpKJubX7 channel-item" @click="openUrlCsusy(config.instagram)">
								<img src="https://cdntoos.w1-pegasopg.com/common/upload/1809372314179784705.png" alt="."
									data-blur="0" data-status="loaded"
									class="goDRiiBsuEuXD3W1NphN jmFqfEJv11xtlFSDmS9f">
								<span class="QFyNFwV7f_hn4Tn_gwqJ channel-name">Instagram</span>
							</li>
							<li class="A_AasZeOJGaSJpKJubX7 channel-item" @click="openUrlCsusy(config.whatsapp)">
								<img src="https://cdntoos.w1-pegasopg.com/common/upload/1828565268666548226.png"
									class="goDRiiBsuEuXD3W1NphN jmFqfEJv11xtlFSDmS9f"><span
									class="QFyNFwV7f_hn4Tn_gwqJ channel-name">WhatsApp</span>
							</li>
						</ul>
					</div>
				</div>
				<!-- #icon_sy_zc_tgzq--svgSprite:all -->

				<!--  #icon_dtfl_dz_0--svgSprite:all-->
				<!--  #icon_dtfl_qkl_0--svgSprite:all-->
				<!--  #icon_dtfl_sw_0--svgSprite:all-->
				<!--   #icon_dtfl_zj_0--svgSprite:all-->
				<!-- #icon_dtfl_sc_0--svgSprite:all -->


			</div>

		</van-popup>

	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import {
		ICON_NAV,
		ICON_NAVActive
	} from "@/assets/public/icon_nav.js"
	import Audio from "@/components/audio/index.vue"
	export default {
		name: 'Icon',
		computed: {
			...mapState(['popList', 'userInfo', 'moneyInfo', 'config'])
		},
		components: {
			Audio
		},
		props: {
			showPopup: {
				type: Boolean,
				required: false,
			},

		},
		data() {
			return {
				show: false,
				iconList: ICON_NAV,
				activeList: ICON_NAVActive,
				SuporteIs: false,
				_i18nLocale: '',
				is_vuex_token: false,
			}
		},
		created() {
			if (this._vuex_token && typeof this._vuex_token() == 'string') {
				this.is_vuex_token = true;
			}
			// this.$u.vuex('v_lang', item)
			// this._i18nLocale
			// console.log(this.$i18n.locale)
			// this.$i18n.locale = 'pt';
			// this.SuporteIs=false;
			// console.log(this.$store.state)
		},
		methods: {
			iconName() {
				return `${this.prefix}${this.name}`
			},
			openUrlCsusy(e) {
				if (e != '#') {
					window.open(e, '_blank');
				}
			},
			switchLang(e = 'en') {
				this.$i18n.locale = e;
				this._i18n.locale = e;
				this.$vuex('_i18nLocale', e)
			},
			tabFun(e) {
				this.$store.commit('active', e)
				this.$store.commit('showPopup', e)
			}
		}
	}
</script>
<style>
	.popup-box {
		top: .901rem !important;
		height: calc(100% - .901rem) !important;
	}

	.popup-box-app {
		top: 1.6rem !important;
		height: calc(100% - 1.6rem) !important;
	}

	.popup-conter {
		top: .9rem !important;
		height: calc(100% - .901rem) !important;
		background-color: var(--cms-background-color-primary) !important;
		max-width: 40% !important;
		bottom: 0;
	}

	html .popup-conter-app {
		/* top: 55% !important; */
		top: 1.6rem !important;
		height: calc(100% - 1.61rem) !important;
		background-color: var(--cms-background-color-primary) !important;
		max-width: 40% !important;
		bottom: 0;
		/* transform: initial; */
	}

	.popup-conter.van-popup--left,
	.popup-conter-app.van-popup--left {
		transform: initial;
	}
</style>
<style>
	.van-popup {
		overflow-y: visible !important;
	}
</style>
<style scoped>
	.searchWrap-AApaE {
	    align-items: center;
	    background-color: var(--cms-background-color-secondary);
	    border-radius: .14rem;
	    display: flex;
	    height: .5rem;
	    overflow: hidden;
	    width: 100%;
	    justify-content: space-between;
	    padding-right: .18rem;
	    color: var(--cms-text-color-secondary);
	    font-size: .22rem;
	    padding-left: .08rem
	}
	.searchWrap-AApaE .fs20{
		width: .22rem;
		height: .22rem;
	}
	.NQ4WM0y42kgXYhY5nCtm {
		margin: .2rem 0;
		width: 100%
	}

	.NQ4WM0y42kgXYhY5nCtm .DBxipifklFUXsk1HBEsp {
		color: var(--cms-global-spare7-color);
		font-size: .24rem;
		font-stretch: normal;
		font-style: normal;
		font-weight: 400;
		letter-spacing: normal;
		line-height: 1.38;
		margin-bottom: .12rem;
		text-align: left
	}

	.NQ4WM0y42kgXYhY5nCtm .VmQmq_aGsrNiKVkUlbUu {
		width: 100%
	}

	.NQ4WM0y42kgXYhY5nCtm .VmQmq_aGsrNiKVkUlbUu .A_AasZeOJGaSJpKJubX7 {
		-ms-flex-align: center;
		-ms-flex-pack: start;
		align-items: center;
		background-color: var(--cms-background-color-secondary);
		border-radius: .1rem;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: flex-start;
		margin-bottom: .1rem;
		padding: .08rem .18rem;
		width: 100%
	}

	.NQ4WM0y42kgXYhY5nCtm .VmQmq_aGsrNiKVkUlbUu .A_AasZeOJGaSJpKJubX7 .jmFqfEJv11xtlFSDmS9f {
		height: .34rem;
		width: .34rem
	}

	.NQ4WM0y42kgXYhY5nCtm .VmQmq_aGsrNiKVkUlbUu .A_AasZeOJGaSJpKJubX7 .QFyNFwV7f_hn4Tn_gwqJ {
		color: var(--cms-global-spare7-color);
		font-size: .22rem;
		font-weight: 400;
		margin-left: .2rem;
		overflow: hidden;
		text-align: left;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%
	}

	.my-scrollbar {
		height: 100%;
	}

	.X_tOtMUxMTenSfTn7J28 {
		-ms-flex-pack: justify;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: .15rem .15rem 0;
	}

	.X_tOtMUxMTenSfTn7J28 .O8zRRZalAqyl6XRHwyev {
		-ms-flex-pack: distribute;
		background: var(--cms-background-color-secondary);
		border-radius: .1rem;
		color: var(--cms-global-spare7-color);
		cursor: pointer;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		height: .7rem;
		justify-content: space-around;
		margin-bottom: .1rem;
		width: 1rem;
	}

	.X_tOtMUxMTenSfTn7J28 .O8zRRZalAqyl6XRHwyev {
		border-radius: .14rem;
		cursor: pointer;
		font-size: .2rem;
		height: .9rem;
		max-width: 1.3rem;
		min-width: 1.3rem;
		width: 1.3rem;
	}

	.X_tOtMUxMTenSfTn7J28 .O8zRRZalAqyl6XRHwyev .JGYMaLsdGfKSq3qags9Y {
		height: .38rem;
		width: .5rem;
		margin: 0 auto;
		color: var(--cms-primary-color);
	}

	.X_tOtMUxMTenSfTn7J28 .O8zRRZalAqyl6XRHwyev .IDcevVi1oQJGuthlvopy {
		font-size: .2rem;
		padding: 0 .04rem;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
	}

	.X_tOtMUxMTenSfTn7J28 .O8zRRZalAqyl6XRHwyev.ia6woxHBwCJOiUSohzV6 {
		background-color: var(--cms-primary-color);
		background-position: 50%;
		background-size: 100% 100%;
		color: var(--cms-theme-text-color-primary);
		position: relative;
		-webkit-transition: all .3;
		transition: all .3;
	}

	.vsmXMvkgXQ1ZupbTcU60 {
		padding: 0 .15rem;
	}

	.JzmqmQEmD_EojrBge1qC {
		font-size: .24rem;
		height: .75rem;
		align-items: center;
		display: flex;
		justify-content: space-around;
		background-color: var(--cms-background-color-secondary);
		border-radius: .1rem;
		color: var(--cms-global-spare7-color);
		cursor: pointer;
		font-size: .24rem;
		margin-top: .1rem;
		width: 100%;
	}

	.JzmqmQEmD_EojrBge1qC_iam {
		width: .36rem;
		height: .36rem;
	}

	.yfF9WZOWUEDWBJAnHyPf {
		-ms-flex-pack: justify;
		background-color: var(--cms-background-color-secondary);
		border-radius: .1rem;
		display: -ms-flexbox;
		display: flex;
		box-sizing: border-box;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: .1rem 0 .05rem;
		padding: .1rem .1rem 0;
		width: 100%;
	}

	.a6z38LxCJRwWacRIx6d9 {
		color: var(--cms-global-spare7-color);
		font-size: .24rem;
		padding-bottom: .1rem;
		text-align: center;
		width: 2.5rem
	}

	._037LxwuCKN9WUGgvrvgg {
		cursor: pointer;
		position: relative;
		margin-bottom: .1rem;
		width: 1.2rem;
		height: 0.8rem;
	}

	._037LxwuCKN9WUGgvrvgg img {
		position: absolute;
		top: 0;
		left: 0;
	}

	._037LxwuCKN9WUGgvrvgg span {
		position: absolute;
		left: .1rem;
		top: 0.06rem;
		font-size: .15rem;
		z-index: 9;
		color: #fff;
	}

	.other-group {
		margin-top: .1rem;
	}

	.contact-us {
		display: flex;
		color: var(--cms-global-spare7-color)
	}

	.bgcolor {
		color: var(--cms-global-spare8-color)
	}

	.bgcolor_a {
		color: var(--cms-global-spare7-color);
		width: .14rem !important;
		height: .14rem !important;
	}

	.dDFOAjUKC5bZ6pGH7_p0 {
		width: .36rem !important;
		height: .36rem !important;
		margin-right: .1rem;
		display: flex;
		height: .334rem;
		margin-right: .193rem;
		background: var(--cms-global-spare7-color);
		border-radius: 50%;
		width: .334rem;
	}

	._llZzyHSRes4dPHH7fN2 {
		font-size: 0.22rem;
	}

	.KYJ6GhRbRQ3eAaeyFhEg {
		cursor: pointer;
		font-size: .22rem;
		min-height: .6rem;
		display: flex;
		align-items: center;
		padding-left: .18rem;
	}

	.ant-popover-inner {
		background: var(--cms-background-color-secondary);
		border: thin solid var(--cms-divider-color);
		border-radius: .1rem;
		position: absolute;
		max-height: 4.7rem;
		right: -1.5rem;
	}

	.ant-popover-inner .ant-menu-item {
		font-size: .24rem;
		height: .8rem;
		line-height: .8rem;
		padding: 0 .2rem;
	}
</style>