<template>
    <div class="ykHuapWkAnn2lG280pUV aside-music">
        <div class="conterarr">
            <div class="musicControlContent ">
                <p style="display: flex;align-items: center;" @click="PreviousSong">
					<svg-icon  className="AsuZcZV62qZP24BHNIay"  icon-class="comm_nav_sys--svgSprite:all"  />
				</p>
                <div class="play_ac" @click="play">
                    <svg-icon   @click="play"  className="play_ac" :icon-class="isPlay?'comm_nav_zt--svgSprite:all':'comm_nav_bf--svgSprite:all'"  />
                </div>
				<p style="display: flex;align-items: center;" @click="NextSong">
					<svg-icon  className="AsuZcZV62qZP24BHNIay"  icon-class="comm_nav_xys--svgSprite:all"  />
				</p>
            </div>
            <div class="LgZXIbBdRX9rLteN1wqj">
                <div class="play_ac" @click="switchFn">
                    <svg-icon  className="play_ac"  :icon-class="Playing=='single'?'comm_icon_dqxh--svgSprite:all':Playing=='random'?'comm_icon_sj--svgSprite:all':'comm_icon_xhbf--svgSprite:all'"  />
                </div>
                <div class="play_ac" style="margin-left: .3rem;" @click="$store.commit('overlayShow')">
                    <svg-icon  className="play_ac"  icon-class="comm_icon_qk--svgSprite:all"  />
                    <div class="tI3J2R7olcpRYNJourYn musicCount">{{myMusicList.length||0}}</div>
                </div>
            </div> 
        </div>
        <div class="aM6hp0yMJ8Iwg5kFwSCI songName">{{$store.state.CurrentPlayback.name}}</div> 
    </div>
  </template>
   
  <script>
	import { mapState } from 'vuex';
  export default {
    props: {
        showPopup:{
            type: Boolean,
            required: false,
        },
    },
	computed:{
		...mapState(['myMusic','myMusicList','songSheetList','CurrentPlayback'])
	},
    data() {
      return {
        isPlay:false,
        Playing:'number',//single 单曲 random  Sequential number 顺数
        overlayShow:true,
      };
    },
	created() {
	},
    methods: {
		PreviousSong(){
				  let _index=this.CurrentPlayback.index-1;
				  if(_index<0){
					  _index=this.myMusic.length-1;
				  }
				  _player.setSong(this.myMusic[_index].src);
				   this.$store.commit('CurrentPlayback',this.myMusic[_index]);
				  _player.play()
				   this.isPlay=_player._state
		},
		NextSong(){
				 let _index=this.CurrentPlayback.index+1;
				 if(_index>this.myMusic.length-1){
				 	_index=0;
				 }
				 
				 _player.setSong(this.myMusic[_index].src);
				  this.$store.commit('CurrentPlayback',this.myMusic[_index]);
				 _player.play()
				  this.isPlay=_player._state
		},
        switchFn(){
            if(this.Playing=='single'){
                this.Playing='random'
            }else if(this.Playing=='random'){
                this.Playing=''
            }else{
                this.Playing='single'
            }
        },
     play(){
     		  if(_player._state){
     			   _player.pause();
     		  }else{
     			_player.play()
     			this.PlayOften=_player._durationArr
     		  }
     		  this.isPlay=_player._state
     },
      pause() {
        
      },
      stop() {
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
      }
    }
  };
  </script>
  <style>
  .addonstr{
    position: fixed;
    z-index: -9999;
    left: -9999px;
  }

    .aside-music {
        background-color: var(--cms-background-color-secondary);
        border-radius: .1rem;
        height: .75rem;
        padding: .04rem .1rem;
       
    }
    .conterarr{
        display: flex;
        align-content: center;
    }
    .musicControlContent{
        padding-top: 0.12rem;
        display: flex;
        height: .24rem;
        justify-content: space-around;
        align-items: center;
        width: 1.5rem;
    }
    .AsuZcZV62qZP24BHNIay {
        color: var(--cms-primary-color);
        height: .16rem !important;
        display: flex;
    }
    .play_ac{
        width: .24rem !important;
        height: .24rem !important;
        color: var(--cms-primary-color);
        display: flex;
        position: relative;
    }
    .tI3J2R7olcpRYNJourYn {
    background-color: var(--cms-background-color-primary);
    border: .01rem solid var(--cms-divider-color);
    border-radius: .2rem;
    color: var(--cms-text-color-secondary);
    left: -.22rem;
    line-height: .19rem;
    color: var(--cms-text-color-secondary);
    font-size: 0.14rem;
    min-width: .3rem;
    padding: 0 .06rem;
    position: absolute;
    text-align: center;
    top: -.14rem;
}
.LgZXIbBdRX9rLteN1wqj{
    display: flex;
    padding-top: 0.12rem;
    align-items: center;
    height: .24rem;
}
.songName {
    font-size: .18rem;
    left: 0;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin-top: .1rem;
    color: var(--cms-global-spare7-color);
}
.document{
    width: 6.9rem; transform-origin: 117.398px 70.1719px;
    max-height: calc(var(--vh, 1vh)* 100);
    background-color: var(--cms-background-color-secondary);
    /* border: thin solid; */
    /* border-color: var(--theme-color-line); */
}
  </style>