<template>
	<div class="" style="background-color: linear-gradient(var(--cms-background-color-primary) 0,var(--cms-background-color-primary) 100%);" @click.stop="isNavigation=false">
		<div class="conterTeng-scrollbar" ref="homeScrollbar" @scroll="handleScroll" :style="{
			height:`calc(100vh)`
		}">
		<!-- 
			
		 -->
		<!-- CancelDisplay 
		 isCancelDisplay=false
		 CancelDisplay
		-->
		    <APopup :isShow="isShowAPopup" @_remove="_remove"></APopup>
			<advertisementHome v-if="popList.length" @CancelDisplay="CancelDisplay" 
				:popList="popList" :isShow="isCancelDisplay"></advertisementHome>
			<sidebars :sidebarsList="sidebarsList"> </sidebars>
			<HeaderCONTER></HeaderCONTER>
			<Swipe :swipeList="banner"></Swipe>
			<div style="--jackpot-card-count-box-scale: 0.66; --jackpot-card-count-box-height: 0.76rem; --jackpot-card-count-to-bottom: 0.245rem; --jackpot-card-width: 100%; --jackpot-card-height: 1.64rem; --jackpot-font-letter-spacing: 0.007rem; --jackpot-card-margin: 0 0 0.2rem; --jackpot-font-color: var(--theme-text-color); --jackpot-font-size: 0.48rem;"
				class="Ks5gwBkzEwTSEHtNE2PQ">
				<!-- https://cdntoos.vr6pg.com/cocos/icon/vjp/1825238600611532802.png -->
				<div class="_VQdc8vOEOzzyOEUKmvZ"
					:style="`background-image: url(${'https://cdntoos.vr6pg.com/cocos/icon/vjp/1825238600611532802.png'});background-size: 100% 100%;background-position: 0 0;`">
					<CountTo :startVal='startVal' @getDisplayValue="getDisplayValue" :endVal='BonusAmount' :decimals='2'
						:duration='5000' />
					<div slot="defaultValue" class="defaultValue"
						style="margin-top: .4rem;display: flex;align-items: center;--jackpot-card-count-box-scale:0.6;transform: scale(var(--jackpot-card-count-box-scale));">
						<p v-for="(item,index) in BonusTrendsAmount" style="display: flex;height: .0.83rem;">
							<i v-if="item==','" :style="{
						  	display:'inline-block',
						  	width:'0.22rem',
						  	height: '0.72rem',
						  	backgroundImage:`url(${_coloImg_se})`,
						  	backgroundPosition:`-4.62rem 0`,
						  	backgroundSize: '8.36rem .72rem'
						  }"></i>
							<i v-else-if="item=='.'" :style="{
								display:'inline-block',
								width:'0.22rem',
								height: '0.72rem',
								backgroundImage:`url(${_coloImg_se})`,
								backgroundPosition:`-4.4rem 0rem`,
								backgroundSize: '8.36rem .72rem'
							}"></i>
							<i v-else :style="{
							display:'inline-block',
							width:'0.44rem',
							height: '0.72rem',
							backgroundImage:`url(${_coloImg_se})`,
							backgroundPosition:`${'-'+(item*0.44)}rem 0rem`,
							backgroundSize: '8.36rem .72rem'
						}"></i>
						</p>
					</div>

				</div>
			</div>
			<div class="notice-bar" style="padding-left: .1rem !important;">
				<!-- notice -->
				<van-notice-bar class="marquee" style="height: 20px;" :left-icon="tongzhi" :scrollable="true">
					<div style="display: flex;align-items: center;">
						<div v-for="(item,index) in marquee" @click="openUrl('notice',{active:3,marqueeIndex:index}) " v-html="item"></div>
					</div>
				</van-notice-bar>
				<i
					:style="`margin-left:5px;display: inline-block; position: relative; width: 0.5rem; height: 0.4375rem; background-image: url(${_coloImg}); background-position: -5.3375rem -4.275rem; background-size: 9.075rem 8.85625rem;`"></i>
			</div>
			<div class="QDagHrn64BFo9TuByPWA">
				<div class="pFlbcXXZFoPW8cshs442" style="font-size: .2rem;" v-if="!vuex_token_head">
					<span class="dAp6RGRuL3x_UZPFpZC0"
						@click="$store.commit('isRecord'),$store.commit('publicFun',['isRecordType',1])">{{$t('Login._Login')}}</span>
					<span class="iA5Pa4sa54o0y6S_sxJr"> Ou </span>
					<span class="dAp6RGRuL3x_UZPFpZC0"
						@click="$store.commit('isRecord'),$store.commit('publicFun',['isRecordType',0])">Cadastre-se agora</span>
				</div>
				<div class="" v-else>
					<div>
						<div class="FQ5QHv_dxJNHEb0Yd8h6">
							<div style="display: contents;">
								<div class="wPXbqIqs76_2fek3czOM vip-badge" style="background: rgb(36, 178, 153);"><img
										src="../../assets/icons/images/img_vip.webp"
										class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton as4hwxmIQa3EAvA75gcx vip-img"><span
										:data-text="userInfo.vip_info.level">{{userInfo.vip_info.level}}</span></div>
							</div>
							<div @click="copyContent(userInfo.user_name)" class="blXLPejbalUrq5IycYVR wJ30VxmrZK3Y5gTd1Zq0 kot86ytTGgQsl2K_lpQ5"
								>
								{{userInfo.user_name}} 
								<svg-icon style="width: 0.28rem; height: 0.28rem;margin-left: .02rem;" className="imageWrap" icon-class="style_1_icon_copy--svgSprite:all" />
							</div>
						</div>
					</div>
					<div style="display: flex;">
						<div class="vy918omw_N7rBkJ235XX"
							style="--row-align: center; --mode: flex; --column-align: center;">
							<div class="lop_top" :style="{
								backgroundImage:`url(${img_hb_frame})`
							}" style="background-position: 0px 0px;background-size: 100% 100%;">
								<i class="yj5rHjAv0U7RlHMzWr7F img skeleton-box" :style="{
									backgroundImage:`url(${_coloImg})`
								}" style="display: inline-block; position: relative; width: 0.3rem; height: 0.3rem;background-position: -7.15714rem -2.79643rem; background-size: 7.77857rem 7.59107rem;"></i>
							</div>
							<section class="_top_currency-count" style="font-size: 0.18rem;">
								<div class="countTextBox" @click="animate">
									<span class="SSAbrhtT3U690CrzLUd5 count-to">
										<span class="notranslate">{{userBalance}}</span>
									</span>
									<svg-icon icon-class="comm_icon_sx--svgSprite:all" />
								</div>
							</section>
						</div>
					</div>
					
				</div>

				<!-- <div class="crcd82r1PVsQivOcCftk" v-if="!vuex_token_head">
				<van-button @click="$store.commit('isRecord'),$store.commit('publicFun',['isRecordType',1])"
					type="primary" size="mini">{{$t('Login._Login')}}</van-button>
				<van-button @click="$store.commit('isRecord'),$store.commit('publicFun',['isRecordType',0])"
					type="default" size="mini"> {{$t('Login._Registro')}} </van-button>
			</div> -->
				<div class="DrSAta3RAIhChb6OSZTc">
					<div class="nGxwk_oNvWwlxdYhmaMt" @click="$router.push({name:'promote'})">
							<i
								:style="{
										backgroundImage:`url(${_coloImg3Dian5})`
									}"
								style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position:8rem 17.4rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
								/>
						<div class="R6JnJ5r1Gimo5SZaqJCu">Agente</div>
					</div>
					<div class="nGxwk_oNvWwlxdYhmaMt" @click="$router.push({name:'vip'})">
							<i
								:style="{
										backgroundImage:`url(${_coloImg3Dian5})`
									}"
								style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position:7.16rem 9.05rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
								/>
						<div class="R6JnJ5r1Gimo5SZaqJCu">VIP</div>
					</div>
					<div class="nGxwk_oNvWwlxdYhmaMt" @click.stop="$store.commit('publicFun',['AppDownload',true])">
							<i
								:style="{
										backgroundImage:`url(${_coloImg3Dian5})`
									}"
								style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position:7.7rem 5.04rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
								/>
						<div class="R6JnJ5r1Gimo5SZaqJCu">Baixar App</div>
					</div>
					<div class="nGxwk_oNvWwlxdYhmaMt" @click.stop="isNavigation=!isNavigation">
						<i
							:style="{
									backgroundImage:`url(${_coloImg3Dian5})`
								}"
							style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position:7.7rem 13.05rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
							/>
						<div class="R6JnJ5r1Gimo5SZaqJCu">Mais</div>
					</div>
					<navigation @ChesngAPopup="_remove" :isNavigation="isNavigation"></navigation>
				</div>
			</div>
			<div class="tabs conter_box conter_box-2" :class="{
			 isTabsShow:isTabsShow,
		 }">
				<van-tabs v-model="$store.state.active" @change="tabFun" ref="navTbaSwConte" @disabled="onClickDisabled"
					scrollspy sticky :offset-top="`${.9}rem`" @scroll="scrollTabs">
					<van-tab v-for="(item,index) in iconList" :key="index" :name="index">
						<div class="h5MidMenuTabTitle h5MidMenuActive" slot="title">
							<div class="imageWrap">
								<svg-icon v-if="$store.state.active!=index"
									style="width: .55rem !important;height: .55rem !important;" className="imageWrap"
									:icon-class="item.svg" />
								<img v-else
									class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton _S3Gqs7Pat3lw5JdNOCd navIcon navIconUrl"
									:src="activeList[index]" alt="">
							</div>
							<!-- <svg-icon v-if="$store.state.active!=index" className="imageWrap" :icon-class="item.svg" /> -->

							<span class="TMZWYZcIv5YQXVdFMVrw inner-text"
								style="-webkit-line-clamp: 2;-webkit-box-orient: vertical;display: -webkit-box;line-height: 1.2;overflow: hidden;overflow: initial;text-align: center;text-overflow: ellipsis;vertical-align: middle;word-break: break-word;">
								{{$t('popup')[item.textName]}}
							</span>
						</div>
						<!-- <div></div> -->
						<div class="vwuYKuCNAmUF0GsyJhVu" style="overflow: hidden;" v-if="tabNav[item.name] && $store.state.active<3"
							>
							<div class="kQtvRRirB33JTKwlSb26 titleWrap"
								style="border-top: .01rem solid var(--theme-color-line);" v-if="index!=0">
								<div class="leftTitle">
									<img class="imageWrap" style="margin-right: .07rem;" :src="activeList[index]"
										alt="">
									{{$t('popup.nav')[index].title}}
								</div>
								<!-- category=slot -->
								<div class="rightTitle" @click="subgame($t('popup.nav')[index])">
									Tudo
								</div>
							</div>
							<div v-else style="height: .34rem;"></div>
							<div class="gameItemBox"  v-if="item.textName=='_Popular' "
								style="width: 7.4rem;--cardRowSpacing: 0.22rem; --cardColSpacing: 0.075rem; margin: -0.22rem -0.075rem;">
								<gameItem3Dina5 v-for="(item_a,index_1) in tabNav[item.name].list" :category="item.name"
									:item="item_a" type="home" :key="index_1"></gameItem3Dina5>
								<div style="display: flex;justify-content: center;width: 100%;">
									<van-empty v-if="!tabNav[item.name].list.length" image-size="106px"
										class="custom-image" :image="img_none_sj"
										description="Nenhum jogo disponível" />
								</div>
							</div>
							<div class="" style="width: 6.4rem;margin: 0 auto;margin-top: -.22rem;" v-else>
								<gameItem3Dina5Dian1 v-for="(item_a,index_1) in tabNav[item.name].list"
									:category="item.name" :item="item_a" type="home" :key="index_1">
								</gameItem3Dina5Dian1>
								<div style="display: flex;justify-content: center;width: 100%;">
									<van-empty v-if="!tabNav[item.name].list.length" image-size="106px"
										class="custom-image" :image="img_none_sj"
										description="Nenhum jogo disponível" />
								</div>
							</div>
							<div class="bkAYznjEc9hUqGQh_a3S scroll-down" @click="congxJiazai(item.name)"
								style="margin-bottom: .3rem;margin-top: .35rem;"
								v-if="tabNav[item.name].now<tabNav[item.name].count && item.textName=='_Popular'">
								{{$t('_home._Aexibir')}} {{tabNav[item.name].now}} {{ $t('_home._jogosentre') }}
								{{tabNav[item.name].count}}{{$t('_home._Popularjogos') }}
								<div class="WGAruokZzpTWkHI2SQ9G scroll-text cursor-pointer">
									{{ $t('_home._Carregarmais') }}
									<svg-icon className="imageWrap_s"
										style="width: .14rem;height: .14rem;vertical-align: unset;"
										icon-class="comm_icon_jzgd--svgSprite:all" />
								</div>
							</div>

						</div>

					</van-tab>
				</van-tabs>
				<div class="vwuYKuCNAmUF0GsyJhVu" v-if="iconList[$store.state.active].textName=='_Recente'">
					<div class="kQtvRRirB33JTKwlSb26 titleWrap">
						<div class="leftTitle">
							<img class="imageWrap" style="margin-right: .07rem;" :src="activeList[$store.state.active]" alt="">
							{{$t('popup.nav')[4].title}}
						</div>
					</div>
					<div class="gameItemBox">
						<gameItem v-for="(item_a,index_1) in recentJson.list"
							:item="item_a" type="home" :key="index_1"></gameItem>
							<div style="display: flex;justify-content: center;width: 100%;">
								<van-empty v-if="!recentJson.list.length" image-size="106px" class="custom-image"
									:image="img_none_sj" description="Nenhum jogo disponível" />
							</div>
					</div>
					<div class="bkAYznjEc9hUqGQh_a3S scroll-down" @click="congxJiazai(iconList[$store.state.active].name)"
						v-if="recentJson.now< recentJson.count">
						{{$t('_home._Aexibir')}} {{recentJson.now}} {{ $t('_home._jogosentre') }}
						{{recentJson.count}}{{$t('_home._Popularjogos') }}
						<div class="WGAruokZzpTWkHI2SQ9G scroll-text cursor-pointer">{{ $t('_home._Carregarmais') }}
							<svg-icon className="imageWrap_s" style="width: .14rem;height: .14rem;vertical-align: unset;"
								icon-class="comm_icon_jzgd--svgSprite:all" />
						</div>
					</div>
				</div>
				<div class="vwuYKuCNAmUF0GsyJhVu" v-if="iconList[$store.state.active].textName=='_Favoritos'">
					<div class="kQtvRRirB33JTKwlSb26 titleWrap">
						<div class="leftTitle">
							<img class="imageWrap" style="margin-right: .07rem;" :src="activeList[$store.state.active]" alt="">
							{{$t('popup.nav')[5].title}}
						</div>
					</div>
					<div class="gameItemBox">
						<gameItem v-for="(item_a,index_1) in favoriteJson.list"
							:item="item_a" type="home" :key="index_1"></gameItem>
							<div style="display: flex;justify-content: center;width: 100%;">
								<van-empty v-if="!favoriteJson.list.length" image-size="106px" class="custom-image"
									:image="img_none_sj" description="Nenhum jogo disponível" />
							</div>
					</div>
					<div class="bkAYznjEc9hUqGQh_a3S scroll-down" @click="congxJiazai(iconList[$store.state.active].name)"
						v-if="favoriteJson.now< favoriteJson.count">
						{{$t('_home._Aexibir')}} {{favoriteJson.now}} {{ $t('_home._jogosentre') }}
						{{favoriteJson.count}}{{$t('_home._Popularjogos') }}
						<div class="WGAruokZzpTWkHI2SQ9G scroll-text cursor-pointer">{{ $t('_home._Carregarmais') }}
							<svg-icon className="imageWrap_s" style="width: .14rem;height: .14rem;vertical-align: unset;"
								icon-class="comm_icon_jzgd--svgSprite:all" />
						</div>
					</div>
				</div>


			</div>
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
	import HeaderCONTER from "@/components/header/fourIndex.vue"
	// @ is an alias to /src
	import Sidebar from "@/components/sidebar/index.vue"
	import Swipe from "@/components/swipe/index.vue"
	import {
		ICON_NAV,
		ICON_NAV3Dian5,
		ICON_NAVActive,
		ICON_NAV3Dian5Active
	} from "@/assets/public/icon_nav.js"
	import Overlay from "@/components/audio/overlay.vue"
	import gameItem from "@/components/gameItem/index.vue"
	import gameItem3Dina5 from "@/components/gameItem/index3.5.vue"
	import gameItem3Dina5Dian1 from "@/components/gameItem/index3.5.1.vue"

	import CountTo from '@/components/vue-count-to/vue-countTo.vue'
	import advertisementHome from "@/components/advertisement/home.vue"
	import navigation from "./four/navigation.vue"
	import Footer from "./four/footer.vue"
	import sidebars from "@/components/sidebars/index3.5.vue"
	import APopup from "@/components/Idioma-popup/index.vue";
	import {
		mapState
	} from 'vuex';
	let time;
	import Vue from 'vue'
	import {
		baseConfig,
		popAndBanner,
		notification,
		jackpot,
		getAllGameCategory,
		getDataByGameCategory
	} from "@/apis/modules/home.js"
	export default {
		name: 'Home',
		components: {
			Sidebar,
			Swipe,
			Overlay,
			sidebars,
			CountTo,
			gameItem,
			gameItem3Dina5,
			gameItem3Dina5Dian1,
			advertisementHome,
			HeaderCONTER,
			Footer,
			navigation,
			APopup
		},
		computed: {
			...mapState(['popList', 'tabNav', 'banner', 'moneyInfo', 'sidebarsList', 'marquee', 'config',
				'vuex_token_head', 'userInfo','isCancelDisplay'
			])
		},
		data() {
			return {
				tongzhi: require('./../../assets/icons/images/icon_dt_pmd.png'),
				coloImgactivity: require('./../../assets/icons/images/1795312712372871170.gif'),
				img_hb_frame: require('./../../assets/icons/images/img_hb_frame.webp'),
				startVal: 0,
				iconList: [],
				isNavigation: false,
				widUrl: window.location.href,
				activeList: ICON_NAV3Dian5Active,
				base_Config: {},
				BonusAmount: '',
				BonusTrendsAmount: '',
				recentJson: {},
				favoriteJson: {},
				thisiTime: '',
				tabState: false,
				_vuex_tokenIs: false,
				scrollDom: "",
				isTabsShow: false,
				tabForm: {
					leftRight: 0,
				},
				isShowAPopup:false,
				userBalance: '0,00',
			}
		},
		created() {
			this.inviteFun();
			if (this._vuex_token && typeof this._vuex_token() == 'string') {
				this.getBalance();
				this._vuex_tokenIs = true;
			}
			this.iconList = ICON_NAV3Dian5;
			let inviteTime=localStorage.getItem('inviteTime');
			if(inviteTime){
				if(inviteTime>new Date().getTime()){
					this.$store.commit('publicFun',['isCancelDisplay',false])
				}else{
					this.$store.commit('publicFun',['isCancelDisplay',true])
				}
			}
			// console.log(inviteTime,new Date().getTime())
			// console.log(inviteTime>new Date().getTime())
			this.getpopAndBanner();
			this.getNotification();
			this.getAllGameCategory();
			this.showRegisterPage();
			this.showBalance();
		},
		mounted() {
			Vue.prototype.homeScrollbarDom = this.$refs.homeScrollbar;
			this.$nextTick(() => {
				let el = this.$refs.navTbaSwConte.$el;
				let scrollDom = el.getElementsByClassName('van-tabs__nav--complete')[0];
				this.scrollDom = scrollDom;
				let offsetWidth = scrollDom.offsetWidth;
				let scrollWidth = scrollDom.scrollWidth;
				let scrollJisuan = scrollWidth - offsetWidth;
				let {
					leftRight
				} = this.tabForm;
				scrollDom.addEventListener('scroll', (e) => {
					if (scrollJisuan <= scrollDom.scrollLeft) {
						this.tabForm.leftRight = 1
					} else if (scrollDom.scrollLeft == 0) {
						this.tabForm.leftRight = 0
					} else if (leftRight != 2 && scrollDom.scrollLeft != 0) {
						this.tabForm.leftRight = 2
					}
				});
			});
			this.getjackpot(0);
		},
		beforeDestroy() {
			clearTimeout(this.thisiTime);
		},
		watch: {
			moneyInfo: {
				handler(val) {
					this.showBalance();
				},
				deep: true
			}
		},
		methods: {
			_remove(e){
				this.isShowAPopup=!this.isShowAPopup
			},
			showBalance() {
				this.userBalance = this.$store.state.config.balance_no_bonus.includes('home') ? this.moneyInfo.balance_coin_str : this.moneyInfo.balance_total_str;
			},
			// isCancelDisplay=false
			// CancelDisplay
			CancelDisplay(e){
				this.$store.commit('publicFun',['isCancelDisplay',false])
				if(e){
					// localStorage.getItem('lifeData');
					window.localStorage.setItem('inviteTime',(new Date()).getTime()+24*60*60*1000)
				}
				// this.CancelDisplay
			},
			showRegisterPage() {
				if (this._vuex_tokenIs || !this.$store.state.config.register_page_pop) return;
				this.$store.commit('isRecord');
				this.$store.commit('publicFun', ['isRecordType', 0]);
			},
			inviteFun() {
				let {id,type}=this.$route.query;
				//console.log(this.$route.query,id,type,'inviteID 22')
				if(id){
					window.localStorage.setItem('inviteID',id)
				}
				// this.$store.commit('isRecord')
				// this.$store.commit('publicFun',['isRecordType',0])
			},
			navTbaSw(e) {
				if (e == 'right') {
					this.scrollDom.scrollLeft = 1000
				}
				if (e == 'left') {
					this.scrollDom.scrollLeft = 0
				}
			},
			beforeChange(name, title) { //

			},
			scrollTabs(e) {},
			handleScroll(e) {
				clearTimeout(time);
				time = setTimeout(() => {
					clearTimeout(time);
					if (e.target.scrollTop > (e.target.offsetHeight / 3 + 59)) {
						this.$store.commit('publicFun', ['Returntotop', true])
					} else if (this.$store.state.Returntotop) {
						this.$store.commit('publicFun', ['Returntotop', false])
					}
					if (e.target.scrollTop > (e.target.scrollHeight - e.target.offsetHeight - 20)) {
						this.isTabsShow = true
					} else {
						this.isTabsShow = false
					}
				}, 10)
			},
			isDisabled(index) {
				let isTrue = false;
				let active = this.$store.state.active;
				if (index == 3) {
					isTrue = true;
					return true;
				}
				if (active < 3) {
					if (index < 3) {
						return false;
					}
					if (index > 3) {
						isTrue = false;
						return true;
					}
				}
				if (active > 3) {
					if (index < 3) {
						isTrue = true;
						return true;
					}
					if (index > 3) {
						isTrue = false;
						return false;
					}
				}
				return isTrue;
			},
			getAllGameCategory() {
				if (this.tabNav.popular) {
					return false;
				}
				getAllGameCategory({}).then(({
					code,
					data = {},
					msg
				}) => {
					this.$store.commit('publicFun', ['tabNav', data])
				})
			},
			goToNewPage(url) {
				if (url == '#') {
					return false;
				}
				window.open(url, '_blank');
			},
			congxJiazai(category, _key_) {
				let pageSize = category == 'popular' && this.$store.state.config.popular_page_size ? this.$store.state.config.popular_page_size : 6;
				let page = this.tabNav[category] ? this.tabNav[category].now/pageSize+1 : 1;
				//console.log(_key_)
				if (_key_) {
					page = this[_key_ + 'Json'].now ? this[_key_ + 'Json'].now/pageSize+1 : 1
				};
				getDataByGameCategory({
					category: category,
					page: page
				}).then(({
					code,
					data = {},
					msg
				}) => {
					if (!_key_) {
						this.tabNav[category] = {
							...this.tabNav[category],
							now: data.now,
							list: [...this.tabNav[category].list, ...data.list]
						}
						this.$store.commit('publicFun', ['tabNav', this.tabNav])
					} else {
						let listz = (this[_key_ + 'Json'].list ? [...this[_key_ + 'Json'].list, ...data.list] : [
							...data.list
						]);
						this[_key_ + 'Json'] = {
							...this[_key_ + 'Json'],
							now: data.now,
							list: listz,
						}
					}

				})
			},
			getDisplayValue(value) {
				this.BonusTrendsAmount = value;
			},
			getpopAndBanner() { //
				if (this.banner.length) {
					return false;
				}
				popAndBanner({}).then(({
					code,
					data = {},
					msg
				}) => {
					let {
						banner,
						pop,
						sidebars
					} = data;
					this.$store.commit('publicFun', ['banner', banner])
					// this.banner = banner;
					if (!this.popList.length) {
						this.$store.commit('popList', pop.map((item) => {
							return {
								...item,
								isShow: true,
							}
						}));
					}
					this.$store.commit('publicFun', ['sidebarsList', sidebars.map((item) => {
						return {
							...item,
							isShow: true,
						}
					})])
				})
			},

			getNotification() {
				if (this.marquee) {
					return false;
				}
				notification({}).then(({
					code,
					data,
					msg
				}) => { //404
					let {
						marquee
					} = data;
					this.$store.commit('publicFun', ['marquee', marquee])
				})
			},
			getjackpot(tim, _false) {
				if (!this.$store.state.config.show_jackpot) return;
				// if(_false){return false}
				clearTimeout(this.thisiTime);
				this.thisiTime = setTimeout(() => {

					jackpot({
						isLOgin: true,
					}).then(({
						code,
						data,
						msg
					}) => {
						this.startVal = data - 1999 || 0
						this.BonusAmount = data || 0;
					})
					if (this.$route.name == 'home') {
						this.getjackpot(10000)
					}
					let {
						code,
						category
					} = this.$route.query;
				}, tim)
			},
			subgame(e){
				//console.log(e.title);
				let tile='slot';
				let platformValue='popular';
				if(e.title=='Pescaria'){
					tile='piscator'
				}
				this.$router.push({name:'subgame',query:{
					category:tile,
					platformValue:"popular",
				}})
				// piscator
			},
			onClickDisabled(e) {
				this.$router.push({
					name: 'subgame',
					query: {
						id: '',
						category: 'demo',
					}
				})
			},
			tabFun(e, title) {

				let category = this.iconList[e].name;
				if (category == 'recent') {
					this.congxJiazai(category, category)
					this[category + 'Json'] = {}

				}
				if (category == 'favorite') {
					this.congxJiazai(category, category)
					this[category + 'Json'] = {}
				}
				// 
				// console.log(name,title)
				// if(e==4){
				//  this.onClickDisabled()
				// }
				this.$store.commit('active', e)
			},
			stickyScroLL(e) {}
		}
	}
</script>
<style>
	.tabs.conter_box-2 {
		height: auto;
	}

	.tabs.conter_box-2 .van-tabs .van-tabs__nav {
		background-color: var(--theme-home-bg);
		border-bottom: .01rem solid var(--theme-color-line);
		height: .94rem;
		padding: .08rem 0 0;
	}

	.tabs.conter_box-2 .van-tabs__wrap--scrollable .van-tab {
		padding: 0;
		display: flex;
	}

	.tabs.conter_box-2 .h5MidMenuTab {
		display: flex;
		height: 100%;
		padding: 0 4px;
		box-sizing: border-box;
		background-color: var(--theme-home-bg);
	}

	.tabs.conter_box-2 .imageWrap {
		font-size: .54rem;
		height: .48rem !important;
		display: flex;
		align-items: center;
		/* margin-right: .16rem; */
		width: .64rem !important;
	}

	.tabs.conter_box-2 .van-tabs__line {
		min-width: 1rem;
	}

	.tabs.conter_box-2 .van-tabs__wrap--scrollable .van-tabs__nav {
		overflow: initial;
		display: flex;
	}

	.tabs.conter_box-2 .game-category-name {
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		line-height: 1.2;
		overflow: hidden;
		overflow: initial;
		text-align: center;
		text-overflow: ellipsis;
		vertical-align: middle;
		word-break: break-word;
		/* width: .6rem !important; */
		max-width: initial;
	}

	.tabs.conter_box-2 .van-tabs__wrap--scrollable .van-tab {
		flex: revert-layer;
	}

	.tabs.conter_box-2 .gameItemBox {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		height: 100%
	}

	.tabs.conter_box-2 .vwuYKuCNAmUF0GsyJhVu {}

	.a {
		background-color: var(--theme-home-bg);
		height: .94rem;
		padding: .08rem 0 0;
	}
</style>
<style scoped>
	.titleWrap{
		width: 100%;
	}
	.titleWrap .rightTitle{
		margin: -.12rem -.2rem;
		padding: .12rem .2rem;
		color: var(--theme-text-color-lighten);
		cursor: pointer;
		font-size: .24rem;
	}
	.blXLPejbalUrq5IycYVR{
		    color: var(--theme-text-color-darken);
		    overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
			display: flex;
			font-weight: 300;
			align-items: center;
			margin-left: .1rem;
	}
	.FQ5QHv_dxJNHEb0Yd8h6{
		display: flex;
		align-items: center;
		margin-bottom: .1rem;
		    font-size: .22rem;
	}
	.FQ5QHv_dxJNHEb0Yd8h6 .wPXbqIqs76_2fek3czOM {
	    border-radius: .04rem;
	    height: .22rem;
	    max-width: .62rem;
	    min-width: .56rem;
		box-sizing: border-box;
	    padding: 0 .04rem 0 .05rem
	}
	.wPXbqIqs76_2fek3czOM {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    border-radius: .04rem 0 .04rem 0;
	    display: -ms-flexbox;
	    display: flex;
	    height: .12rem;
	    justify-content: center;
	    max-width: .33rem;
	    min-width: .29rem
	}
	.FQ5QHv_dxJNHEb0Yd8h6 .vip-img {
	    border-radius: 0;
	    border-radius: initial;
	    min-width: 0!important;
	    min-width: auto!important;
	    width: .276rem
	}
	.wPXbqIqs76_2fek3czOM span:before {
	    -webkit-background-clip: text;
	    background-clip: text;
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#f7ea94),color-stop(51%,#e5b952),to(#ce9510));
	    background-image: linear-gradient(180deg,#f7ea94 0,#e5b952 51%,#ce9510);
	    background-size: cover;
	    content: attr(data-text);
	    height: 100%;
	    left: 0;
	    position: absolute;
	    text-shadow: none;
	    top: 0;
	    width: 100%;
	    z-index: 0
	}
	.wPXbqIqs76_2fek3czOM span {
	    color: transparent;
	    display: inline-block;
	    font-size: .14rem;
	    font-style: normal;
	    font-weight: 700;
	    margin: -.02rem 0 0;
	    position: relative;
	    text-shadow: 0 .01rem 0 rgba(0,0,0,.4);
	    -webkit-transform: scale(.6);
	    transform: scale(.6)
	}

	.FQ5QHv_dxJNHEb0Yd8h6 span {
	    background: transparent none repeat 0 0/auto auto padding-box border-box scroll;
	    background: initial;
	    font-size: .28rem;
	    font-style: italic;
	    margin-top: -.025rem
	}

	.vy918omw_N7rBkJ235XX {
		height: .4rem;
		padding: 0 .084rem 0 .02rem;
		border: thin solid var(--cms-divider-color);
		border-radius: .2rem;
		-ms-flex-align: var(--column-align);
		-ms-flex-pack: var(--row-align);
		align-items: var(--column-align);
		display: var(--mode);
		height: .4rem;
		justify-content: var(--row-align);
		line-height: normal;
		padding: 0 .05rem 0 .02rem;
		position: relative;
		width: auto;
		
	}

	.lop_top {
		width: .34rem;
		height: .34rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	._top_currency-count .notranslate {
		color: var(--cms-top-money-color);
		font-size: .26rem;
		white-space: nowrap;
		/* 防止文本换行 */
		overflow: hidden;
		/* 隐藏超出容器的文本 */
		text-overflow: ellipsis;
		/* 在文本末尾添加省略号 */
		max-width: 1.2rem;
		display: inline-block;
	}

	._top_currency-count .countTextBox {
		display: flex;
		align-items: center;
	}

	._top_currency-count .svg-icon {
		color: var(--cms-top-btn-color);
		font-size: .293rem;
		width: .293rem;
		height: .293rem;
		z-index: 10
	}

	._top_currency-count .SSAbrhtT3U690CrzLUd5 {
		display: block;
		margin: -.06rem .073rem 0 .1rem;
		max-width: 1.64rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		border-bottom: .02rem solid var(--cms-top-money-color);
	}

	.Ks5gwBkzEwTSEHtNE2PQ {
		margin: 0 .2rem;
	}

	._VQdc8vOEOzzyOEUKmvZ {
		cursor: pointer;
		height: var(--jackpot-card-height);
		margin: var(--jackpot-card-margin);
		position: relative;
		width: var(--jackpot-card-width)
	}

	.defaultValue {
		justify-content: center;
	}

	.marquee {
		background-color: transparent !important;
	}

	.QDagHrn64BFo9TuByPWA {
		padding: 0 .1rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.dAp6RGRuL3x_UZPFpZC0 {
		color: var(--theme-primary-color);
		cursor: pointer;
	}

	.iA5Pa4sa54o0y6S_sxJr {
		color: var(--theme-text-color);
	}

	.DrSAta3RAIhChb6OSZTc {
		display: flex;
		position: relative;
		flex-wrap: wrap;
	}

	.nGxwk_oNvWwlxdYhmaMt {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 1;
		flex: 1;
		-ms-flex-direction: column;
		flex-direction: column;
		margin-right: .05rem;
		max-width: 1rem;
		min-width: .9rem;
		position: relative
	}

	.R6JnJ5r1Gimo5SZaqJCu {
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		color: var(--theme-text-color);
		display: -webkit-box;
		font-size: .2rem;
		line-height: 1.1;
		margin-top: .062rem;
		overflow: hidden;
		text-align: center;
		text-overflow: ellipsis;
		vertical-align: middle;
		width: 1rem
	}

	.h5MidMenuTabTitle {
		-ms-flex-align: center;
		align-items: center;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex;
		font-size: .24rem;
		height: .94rem
	}

	.h5MidMenuTabTitle .imageWrap {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		-ms-flex: none;
		flex: none;
		min-height: .4rem;
		width: .64rem
	}

	.N4LsWhJBhqqyTkv6V0NE {
		display: inline-block;
		line-height: 0;
		overflow: hidden;
	}

	.h5MidMenuTabTitle .navIcon {
		font-size: .54rem;
		height: .48rem;
		margin-right: .16rem;
		width: .64rem
	}
</style>